import { useQueryClient } from "react-query";
import { useMutation } from "react-query";
import { addPoint, updatePoint } from "../data/pointReducer";
import { useSupabaseClient } from "@supabase/auth-helpers-react";

export const useAddListMutation = (queryKey) => {
  const queryClient = useQueryClient();
  const supabase = useSupabaseClient();

  const createList = async (query) => {
    const { data: list, error } = await supabase
      .from('list')
      .insert(query)
      .select()
      .single()

    if (error) {
      console.log('error', error)
      throw new Error(error.message)
    } else {
      return list
    }
  }

  return useMutation(createList, {
    onMutate: async (newList) => {
      await queryClient.cancelQueries(queryKey);

      const previousBoard = queryClient.getQueryData(queryKey);

      const tmpId = `tmp_${Date.now()}`;

      queryClient.setQueryData(queryKey, (data) =>
        addPoint(data, {
          point: {
            id: tmpId,
            cards: [],
            ...newList,
          },
        })
      );

      return { previousBoard, tmpId };
    },
    onSuccess: async (newList, variables, context) => {
      queryClient.setQueryData(queryKey, (data) =>
        updatePoint(data, { id: context.tmpId }, newList)
      );
    },
    onError: async (_err, _newCard, context) => {
      queryClient.setQueryData(queryKey, context.previousBoard);
    },
    onSettled: () => {
      // queryClient.invalidateQueries('board')
    },
  });
};
