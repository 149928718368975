import { useQueryClient } from "react-query";
import { updateSection } from "../data/sectionReducer";
import { useMutation } from "react-query";
import { useSupabaseClient } from "@supabase/auth-helpers-react";

export const useUpdateCardMutation = (queryKey) => {
  const queryClient = useQueryClient();
  const supabase = useSupabaseClient();

  const updateCard = async (query) => {
    const { data: card, error } = await supabase
      .from('card')
      .update(query)
      .select()
      .single()
      .eq('id', query.id)

    if (error) {
      console.log('error', error)
      throw new Error(error.message)
    } else {
      return card
    }
  }

  return useMutation(updateCard, {
    onMutate: async (newCard) => {
      await queryClient.cancelQueries(queryKey);

      const previousBoard = queryClient.getQueryData(queryKey);

      queryClient.setQueryData(queryKey, (data) =>
        updateSection(data, { id: newCard.id }, newCard)
      );

      return { previousBoard };
    },
    onSuccess: async (newCard, variables, context) => {
      queryClient.setQueryData(queryKey, (data) =>
        updateSection(data, { id: newCard.id }, newCard)
      );
    },
    onError: async (_err, _newCard, context) => {
      queryClient.setQueryData(queryKey, context.previousBoard);
    },
    onSettled: () => {
      // queryClient.invalidateQueries('board')
    },
  });
};
