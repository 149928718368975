import { StrictMode } from "react";
import ReactDOM from "react-dom";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import './styles/index.scss';

import { Root } from "./routes/Root";
import { NotFound } from "./routes/NotFound";
import { Dashboard } from "./routes/Dashboard";
import { Board } from "./routes/Board";
import { QueryClient } from "react-query";
import { Error } from "./routes/Error";
import { Policies } from "./routes/policies/Policies";
import { PoliciesLinks } from "./routes/policies/PoliciesLinks";
import { TermsOfService, loader as tosLoader } from "./routes/policies/TermsOfService";
import { EULA, loader as eulaLoader } from "./routes/policies/EULA";
import { PrivacyPolicy, loader as privacyLoader } from "./routes/policies/PrivacyPolicy";
import { CookiePolicy, loader as cookieLoader } from "./routes/policies/CookiePolicy";
import { Register } from "./routes/oauth/Register";

const queryClient = new QueryClient();

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root queryClient={queryClient} />,
    errorElement: <NotFound />,
    children: [
      {
        path: "",
        element: <Dashboard />,
      },
      {
        path: "board/:id/:name?",
        element: <Board />,
      }
    ]
  },
  {
    path: "/error/:type?",
    element: <Error />,
  },
  {
    path: "/oauth/register/:code",
    element: <Register />,
  },
  {
    path: "/policies",
    element: <Policies />,
    children: [
      {
        path: "",
        element: <PoliciesLinks />,
      },
      {
        path: "eula",
        loader: eulaLoader,
        element: <EULA />,
      },
      {
        path: "terms-of-service",
        loader: tosLoader,
        element: <TermsOfService />,
      },
      {
        path: "privacy-policy",
        loader: privacyLoader,
        element: <PrivacyPolicy />,
      },
      {
        path: "cookie-policy",
        loader: cookieLoader,
        element: <CookiePolicy />,
      },
    ],
  },
  {
    path: "*",
    element: <NotFound />,
  }
]);

const rootElement = document.getElementById("root");
ReactDOM.render(
  <StrictMode>
    <RouterProvider router={router} />
  </StrictMode>,
  rootElement
);
