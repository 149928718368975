let SECTION_ID = Date.now();

export const addSection = (
  data,
  { pointId, section: inSection, sectionId, index, text, focus = false }
) => {
  const section = inSection
    ? { ...inSection }
    : {
        id: sectionId ?? SECTION_ID++,
        text: text ?? "",
      };

  return {
    ...data,
    lists: data.lists.map((list) => {
      if (list.id !== pointId) {
        return list;
      }
      const cards = [...list.cards];
      if (typeof index === "number") {
        cards.splice(index, 0, section);
      } else {
        cards.push(section);
      }
      return {
        ...list,
        cards,
      };
    }),
  };
};

export const deleteSection = (data, { id }) => {
  return {
    ...data,
    lists: data.lists.map((list) => {
      return {
        ...list,
        cards: list.cards
          .filter((card) => card.id !== id)
          .map((card, i) => {
            return {
              ...card,
              index: i,
            };
          }),
      };
    }),
  };
};

export const updateSection = (data, oldCard, newCard) => {
  return {
    ...data,
    lists: data.lists.map((list) => {
      return {
        ...list,
        cards: list.cards.map((card) => {
          if (card.id === oldCard.id) {
            return {
              ...card,
              ...newCard,
            };
          }
          return card;
        }),
      };
    }),
  };
};

export const moveSection = (data, { section, listId, index }) => {
  return addSection(deleteSection(data, { id: section.id }), {
    pointId: listId,
    section,
    index,
  });
};

export const focusSection = (data, args) => {
  return {
    ...data,
    focusedSection: args?.id ?? null,
  };
};
