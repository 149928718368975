import { FiColumns } from "react-icons/fi";
import { Link } from "react-router-dom";
import { getBoardUrl } from "../routes/Board";

export const SectionBoard = ({ section }) => {
  return (
    <Link to={getBoardUrl(section.board.board)}
      className="flex items-center card__btn text-left px-2 py-2 hover:opacity-70 rounded-t-md"
    >
      <FiColumns className="mr-2" />
      <span className="font-semibold">{section.board.board.name}</span>
    </Link>
  );
};
