import { Link } from "react-router-dom";
import { H1, P } from "../../components/markdown";

export const PoliciesLinks = () => {
  return (
    <div>
      <H1>Flo Sho policies</H1>
      <P>By using the Flo Sho services you agree to the following policy agreements:</P>
      <ul className="list-disc pl-5">
        <li className="mb-2">
          <Link to="/policies/eula" className="underline hover:opacity-60">
            End User License Agreement
          </Link>
        </li>
        <li className="mb-2">
          <Link to="/policies/terms-of-service" className="underline hover:opacity-60">
            Terms of Service
          </Link>
        </li>
        <li className="mb-2">
          <Link to="/policies/privacy-policy" className="underline hover:opacity-60">
            Privacy Policy
          </Link>
        </li>
        <li className="mb-2">
          <Link to="/policies/cookie-policy" className="underline hover:opacity-60">
            Cookie Policy
          </Link>
        </li>
      </ul>
    </div>
  );
};