import { useQueryClient } from "react-query";
import { useMutation } from "react-query";
import { moveSection } from "../data/sectionReducer";
import { useSupabaseClient } from "@supabase/auth-helpers-react";

export const useMoveCardMutation = (queryKey) => {
  const queryClient = useQueryClient();
  const supabase = useSupabaseClient();

  const setCardIndex = async (query) => {
    const { error } = await supabase
      .rpc('move_card', {
        target_list_id: query.listId,
        card_id: query.card.id,
        new_index: query.index,
      })

    if (error) {
      console.log('error', error)
      throw new Error(error.message)
    }
  }

  const mutation = useMutation(setCardIndex, {
    onMutate: async ({ previousBoard }) => {
      await queryClient.cancelQueries(queryKey);

      return { previousBoard };
    },
    onError: async (_err, _newCard, context) => {
      console.log("error");
      queryClient.setQueryData(queryKey, context.previousBoard);
    },
  });

  return {
    ...mutation,
    mutate: (props) => {
      // for some reason the mutate function is called after some small time,
      // but the draggable API requires the model to be updated immediately.
      // hence we need to update the model a bit earlier.
      const { card, listId, index } = props;
      const previousBoard = queryClient.getQueryData(queryKey);

      queryClient.setQueryData(queryKey, (data) => {
        return moveSection(data, { section: card, listId, index });
      });

      return mutation.mutate({
        ...props,
        previousBoard,
      });
    },
  };
};
