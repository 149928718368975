import { useState } from "react";
import { FiPlus, FiSearch, FiHome } from "react-icons/fi";
import { useCreateBoardMutation } from "../hooks/board/useCreateBoardMutation";
import { HeaderMenu } from "./HeaderMenu";
import { Link } from "react-router-dom";
import { LoginForm } from "./LoginForm";
import { RegisterForm } from "./RegisterForm";
import { useUser } from "@supabase/auth-helpers-react";
import { MultiSearch } from "./MultiSearch";

export const Header = () => {
  const { mutate: mutateCreateBoard } = useCreateBoardMutation([]);
  const [searchOpen, setSearchOpen] = useState(false);
  const [showRegister, setShowRegister] = useState(false);
  const [showLogin, setShowLogin] = useState(false);

  const user = useUser();

  const onShowRegister = async () => {
    if (showLogin) {
      setShowLogin(false);
      await new Promise((resolve) => setTimeout(resolve, 100));
    }
    setShowRegister(true);
  };

  const onShowLogin = async () => {
    if (showRegister) {
      setShowRegister(false);
      await new Promise((resolve) => setTimeout(resolve, 100));
    }
    setShowLogin(true);
  };

  const createBoard = async () => {
    mutateCreateBoard();
  };

  return (
    <>
      {(showRegister || showLogin) && (
        <div className="fixed top-0 left-0 w-full h-full z-10 bg-slate-100/80">
        </div>
      )}
      <div className="z-20 shrink-0">
        {showRegister && (
          <RegisterForm onClose={() => setShowRegister(false)} />
        )}
        {showLogin && (
          <LoginForm onClose={() => setShowLogin(false)} />
        )}
      </div>
      <div className="relative flex shrink-0 items-center px-1 mx-1 my-1 h-10 rounded-lg z-20 bg-white shadow-sm">
        {user ? (
          <div className="w-full flex flex-1 justify-between items-center animate-appear px-2">
            <div className="flex items-center">
              <Link to="/"
                className="c-card-btn flex items-center justify-center w-6 h-6 rounded-sm hover:bg-zinc-100 mr-2"
              >
                <FiHome />
              </Link>
              <div className="md:mr-2">
                <button className="c-btn-w-icon mr-2" onClick={createBoard}>
                  <span className="flex items-center">
                    <FiPlus />
                    <span className="whitespace-nowrap hidden md:inline">Create board</span>
                  </span>
                </button>
              </div>
              {!searchOpen && (
                <button className="c-btn-w-icon mr-2" onClick={() => setSearchOpen(true)}>
                  <span className="flex items-center">
                    <FiSearch />
                    <span className="whitespace-nowrap hidden md:inline">Search</span>
                  </span>
                </button>
              )}
              <MultiSearch open={searchOpen} openChange={setSearchOpen} />
            </div>
            <div className="min-w-0 flex items-center">
              <div className="text-sm mr-2 whitespace-nowrap text-ellipsis overflow-hidden">
                {user.user_metadata.name} <span className="text-gray-400">/ {user.email}</span>
              </div>
              <HeaderMenu />
            </div>
          </div>
        ) : (
          <div className="flex justify-between w-full px-2 animate-appear">
            <div className="flex justify-end">
              <button type="button" className={`c-btn ${showRegister ? 'underline' : ''}`} onClick={onShowRegister}>
                Register
              </button>

              <span className="mx-2">or</span>

              <button type="button" className={`c-btn ${showLogin ? 'underline' : ''}`} onClick={onShowLogin}>
                Login
              </button>
            </div>
            <div className="flex items-center">
              <HeaderMenu />
            </div>
          </div>
        )}
      </div>
    </>
  );
};
