import { useQueryClient } from "react-query";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { getBoardUrl } from "../../routes/Board";
import { useSupabaseClient, useUser } from "@supabase/auth-helpers-react";

export const useCreateBoardMutation = (queryKey) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const supabase = useSupabaseClient();
  const user = useUser();

  const createBoard = async () => {
    const { data: board, error } = await supabase
      .from('board')
      .insert({
        name: 'New Board',
        user_id: user.id,
      })
      .select()
      .single()

    if (error) {
      console.log('error', error)
      throw new Error(error.message)
    } else {
      return board
    }
  }

  return useMutation(createBoard, {
    onMutate: async (newBoard) => {
      await queryClient.cancelQueries(queryKey);

      const previousBoard = queryClient.getQueryData(queryKey);

      const tmpId = `tmp_${Date.now()}`;

      queryClient.setQueryData(queryKey, () => ({
        id: tmpId,
        ...newBoard,
      }));

      return { previousBoard, tmpId };
    },
    onSuccess: async (newBoard, variables, context) => {
      queryClient.setQueryData(queryKey, () => newBoard);

      navigate(getBoardUrl(newBoard), {
        state: {
          isNew: true,
        },
      });
    },
    onError: async (_err, _newCard, context) => {
      queryClient.setQueryData(queryKey, context.previousBoard);
    },
    onSettled: () => {
      // queryClient.invalidateQueries('board')
    },
  });
};
