import {
  createContext,
  useContext,
  useEffect,
  useReducer,
} from "react";

export const BoardContext = createContext({
  board: null,
});

const boardReducer = (state, action) => {
  switch (action.type) {
    case "SET_BOARD":
      return {
        ...state,
        board: action.payload.board
          ? {
            id: parseInt(action.payload.board.id),
          }
          : null,
      };
  }
  return state;
};

export const BoardProvider = ({ routeId, children }) => {
  const [{ board }, dispatchBoard] = useReducer(boardReducer, {
    board: null,
  });

  useEffect(() => {
    if (routeId) {
      dispatchBoard({
        type: "SET_BOARD",
        payload: {
          board: {
            id: routeId,
          },
        },
      });
    }
  }, [routeId]);

  return (
    <BoardContext.Provider value={{ board }}>
      {children}
    </BoardContext.Provider>
  );
};

export const useBoard = () => {
  return useContext(BoardContext);
};
