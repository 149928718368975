import { useQueryClient } from "react-query";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { getBoardUrl } from "../../routes/Board";
import { useSupabaseClient, useUser } from "@supabase/auth-helpers-react";

export const useDeleteBoardMutation = (queryKey) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const supabase = useSupabaseClient();
  const user = useUser();

  const deleteBoard = async (query) => {
    const { error } = await supabase
      .from('board')
      .delete()
      .match({
        id: query.id,
        user_id: user.id,
      });

    if (error) {
      console.log('error', error)
      throw new Error(error.message)
    }
  }

  return useMutation(deleteBoard, {
    onMutate: async () => {
      await queryClient.cancelQueries(queryKey);

      const previousBoard = queryClient.getQueryData(queryKey);

      const tmpId = `tmp_${Date.now()}`;

      queryClient.setQueryData(queryKey, () => null);

      return { previousBoard, tmpId };
    },
    onSuccess: async (board, variables, context) => {
      if (context.previousBoard.parent) {
        navigate(getBoardUrl(context.previousBoard.parent.card.list.board));
      } else {
        navigate('/');
      }
    },
    onError: async (_err, _newCard, context) => {
      queryClient.setQueryData(queryKey, context.previousBoard);
    },
    onSettled: () => {
      // queryClient.invalidateQueries('board')
    },
  });
};
