import { Menu, Transition } from "@headlessui/react";
import { marked } from "marked";
import { Fragment, useEffect, useRef, useState } from "react";
import { FiMenu, FiColumns, FiTrash2, FiArrowUp, FiArrowDown } from "react-icons/fi";
import { FaRegStar, FaStar } from "react-icons/fa";
import { ColorPicker } from "./ColorPicker";
import { Modal } from "./Modal";
import { useBoard } from "../hooks/useBoard";
import { useDeleteCardMutation } from "../hooks/card/useDeleteCardMutation";
import { useUpdateCardMutation } from "../hooks/card/useUpdateCardMutation";
import { useConvertCardToBoardMutation } from "../hooks/card/useConvertCardToBoardMutation";
import { useFavoriteCardMutation } from "../hooks/card/useFavoriteCardMutation";
import { useUnfavoriteCardMutation } from "../hooks/card/useUnfavoriteCardMutation";
import { useDevice } from "../hooks/useDevice";
import { colors } from "./ColorPicker";

export const SectionMenu = ({ section, queryKey: customQueryKey, options, lift }) => {
  const { board } = useBoard();
  const queryKey = customQueryKey ?? ["board", { id: board?.id }];
  const { isMobile } = useDevice();
  const { mutate: mutateDeleteCard } = useDeleteCardMutation(queryKey);
  const { mutate: mutateUpdateCard } = useUpdateCardMutation(queryKey);
  const { mutate: mutateConvertCardToBoard } =
    useConvertCardToBoardMutation(queryKey);
  const { mutate: mutateFavoriteCard } = useFavoriteCardMutation(queryKey);
  const { mutate: mutateUnfavoriteCard } = useUnfavoriteCardMutation(queryKey);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showColorModal, setShowColorModal] = useState(false);
  const [selectedColor, setSelectedColor] = useState(
    colors.find((c) => c.name === (section?.color || "white"))
  );
  const menuRef = useRef(null);
  const [menuPosition, setMenuPosition] = useState("mt-2 origin-bottom-right");

  const menuOptions = {
    moveUp: false,
    moveDown: false,
    previousListId: null,
    nextListId: null,
    delete: true,
    convert: true,
    favorite: true,
    color: true,
    ...(options ?? {}),
  };

  useEffect(() => {
    const onScroll = () => {
      const rect = menuRef.current?.getBoundingClientRect();
      setMenuPosition(
        rect && rect.y < 300
          ? "origin-top-right"
          : "bottom-full mt-2 origin-bottom-right"
      );
    };

    onScroll();

    document.body.addEventListener("scroll", onScroll);

    return () => {
      document.body.removeEventListener("scroll", onScroll);
    };
  }, [section, menuRef]);

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const closeColorModal = () => {
    setShowColorModal(false);
  };

  const saveColor = () => {
    if (selectedColor?.name !== section?.color) {
      mutateUpdateCard({
        id: section.id,
        color: selectedColor?.name,
      });
    }
    closeColorModal();
  };

  const items = [
    ...(menuOptions.moveUp || menuOptions.previousListId ? [{
      icon: <FiArrowUp />,
      text: "Move up",
      action: () => {
        if (!menuOptions.moveUp && menuOptions.previousListId) {
          lift({
            id: section.id,
            parentListId: menuOptions.previousListId,
            last: true,
          });
          return;
        }
        const drag = lift(`section-${section.id}`);
        drag?.moveUp();
        drag?.drop();
      },
    }] : []),
    ...(menuOptions.moveDown || menuOptions.nextListId ? [{
      icon: <FiArrowDown />,
      text: "Move down",
      action: () => {
        if (!menuOptions.moveDown && menuOptions.nextListId) {
          lift({
            id: section.id,
            parentListId: menuOptions.nextListId,
            last: false,
          });
          return;
        }
        const drag = lift(`section-${section.id}`);
        drag?.moveDown();
        drag?.drop();
      },
    }] : []),
    ...(menuOptions.delete ? [{
      icon: <FiTrash2 />,
      text: section.board ? "Delete board" : "Delete card",
      action: () => {
        setShowDeleteModal(true);
      },
    }] : []),
    ...(menuOptions.favorite ? [{
      icon: section.favorite ? <FaStar /> : <FaRegStar />,
      text: section.favorite ? "Remove from highlights" : "Add to highlights",
      action: () => {
        if (section.favorite) {
          mutateUnfavoriteCard({
            id: section.id,
          });
        } else {
          mutateFavoriteCard({
            id: section.id,
          });
        }
      },
    }] : []),
    ...(section.board || !menuOptions.convert
      ? []
      : [
        {
          icon: <FiColumns />,
          text: "Turn into board",
          action: () => {
            var e = document.createElement("div");
            e.innerHTML = marked.parse(section.text);

            mutateConvertCardToBoard({
              id: section.id,
            });
          },
        },
      ]),
    ...(menuOptions.color ? [{
      button: ({ section, action }) => <ColorPicker section={section} action={action} />,
      action: () => {
        if (!isMobile) {
          return;
        }
        setShowColorModal(true);
      },
    }] : []),
  ];

  return (
    <>
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button
            className="c-card-btn flex items-center justify-center w-6 h-6 rounded-sm"
            ref={menuRef}
          >
            <FiMenu />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items
            className={`z-20 absolute right-0 w-56 bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none ${menuPosition}`}
          >
            {items.map(({ text, icon, action, button }, index) => (
              <Fragment key={index}>
                {button ? (
                  button({ section, action })
                ) : (
                  <div className="px-1 py-1 relative">
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          className={`${active && "bg-gray-200"
                            } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                          onClick={action}
                        >
                          {icon && (
                            <div className="flex justify-center w-4 mr-2">
                              {icon}
                            </div>
                          )}
                          {text}
                        </button>
                      )}
                    </Menu.Item>
                  </div>
                )}
              </Fragment>
            ))}
          </Menu.Items>
        </Transition>
      </Menu>
      <Modal
        open={showDeleteModal}
        title="Delete card?"
        onClose={closeDeleteModal}
      >
        <div className="mt-4 flex">
          <button className="c-btn-secondary mr-2" onClick={closeDeleteModal}>
            No
          </button>
          <button
            className="c-btn-primary"
            onClick={() => {
              closeDeleteModal();
              mutateDeleteCard({ id: section.id });
            }}
          >
            Yes
          </button>
        </div>
      </Modal>
      <Modal
        open={showColorModal}
        title="Set card color"
        onClose={closeColorModal}
      >
        {colors.map((color) => (
          <button
            key={color.name}
            className={`cursor-pointer select-none flex w-full items-center py-2 pl-4 pr-4 ${ color.name === selectedColor.name ? 'bg-gray-200' : ''}`}
            onClick={() => {
              setSelectedColor(color);
            }}
          >
            <div
              className={`mr-2 w-4 h-4 rounded-sm border border-gray-500 card-${color.name}`}
            />
            {color.name}
          </button>
        ))}
        <div className="mt-4 flex">
          <button className="c-btn-secondary mr-2" onClick={closeColorModal}>
            Cancel
          </button>
          <button
            className="c-btn-primary"
            onClick={saveColor}
          >
            Save
          </button>
        </div>
      </Modal>
    </>
  );
};
