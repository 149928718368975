import { useQueryClient } from "react-query";
import { deleteSection } from "../data/sectionReducer";
import { useMutation } from "react-query";
import { useSupabaseClient } from "@supabase/auth-helpers-react";

export const useDeleteCardMutation = (queryKey) => {
  const queryClient = useQueryClient();
  const supabase = useSupabaseClient();

  const deleteCard = async (query) => {
    const { error } = await supabase
      .from('card')
      .delete()
      .eq('id', query.id);

    if (error) {
      console.log('error', error)
      throw new Error(error.message)
    }
  }

  return useMutation(deleteCard, {
    onMutate: async (newCard) => {
      await queryClient.cancelQueries(queryKey);

      const previousBoard = queryClient.getQueryData(queryKey);

      if (queryKey.length && queryKey[0] === "favorites") {
        queryClient.setQueryData(queryKey, (data) => data.filter((c) => c.id !== newCard.id));
      } else {
        queryClient.setQueryData(queryKey, (data) =>
          deleteSection(data, newCard)
        );
      }

      return { previousBoard };
    },
    onError: async (_err, _newCard, context) => {
      queryClient.setQueryData(queryKey, context.previousBoard);
    },
  });
};
