import { useCallback, useEffect, useState } from "react";
import { FiX } from "react-icons/fi";
import { FcGoogle } from "react-icons/fc";
import { useSupabaseClient, useUser } from "@supabase/auth-helpers-react";
import { Modal } from "./Modal";
import { PolicyAgreementNoCheck } from "./PolicyAgreementNoCheck";

export const LoginForm = ({ onClose }) => {
  const supabase = useSupabaseClient();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginError, setLoginError] = useState(null);
  const [showRegistrationModal, setShowRegistrationModal] = useState(false);
  const [visible, setVisible] = useState(false);

  const user = useUser();

  const login = useCallback(async (e) => {
    e.preventDefault();
    try {
      setLoginError(null);

      const { data, error } = await supabase.auth.signInWithPassword({
        email,
        password,
        options: {
          redirectTo: process.env.REACT_APP_APP_URL,
        },
      });

      if (error) {
        setLoginError(error.message);
      } else {
        if (data.user && data.session) {
          onClose();
        } else if (data.user && !data.session) {
          setShowRegistrationModal(true);
        } else {
          setLoginError('Failed to log in');
        }
      }
    } catch (error) {
      console.log(error);
      setLoginError(error.message);
    }
  }, [email, password, supabase]);

  const signInWithGoogle = async () => {
    const { error } = await supabase.auth.signInWithOAuth({
      provider: 'google',
      options: {
        redirectTo: process.env.REACT_APP_APP_URL,
      },
    })

    if (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (user) {
      onClose();
    }
  }, [user, onClose]);

  useEffect(() => {
    setVisible(true);
  }, []);

  return (<>
    <div className={`overflow-hidden transition-all ease-in-out duration-300 ${visible ? 'max-h-[30rem]' : 'max-h-0'}`}>
      <div className="relative p-6 bg-slate-100">
        <h2 className="text-xl font-bold mb-2">Log in</h2>
        <div className="flex flex-col sm:flex-row max-w-3xl">
          <form className="border-b pb-4 sm:w-1/2 sm:pb-0 sm:pr-4 sm:border-b-0 sm:border-r border-slate-300" onSubmit={login}>
            <label htmlFor="login-email">Email *</label>
            <input
              className="c-input u-mb-1"
              id="login-email"
              type="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              autoFocus={true}
              required={true}
            />
            <label htmlFor="login-password">Password *</label>
            <input
              className="c-input mb-2"
              id="login-password"
              type="password"
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required={true}
            />
            {loginError && (
              <p className="text-red-500 mb-2">{loginError}</p>
            )}
            <button type="submit" className="c-btn c-btn-primary h-12 mt-2">
              Log in
            </button>
          </form>
          <form
            className="pt-4 sm:pt-0 sm:w-1/2 sm:pl-4"
          >
            <PolicyAgreementNoCheck />
            <button
              type="button"
              onClick={signInWithGoogle}
              className="c-btn flex items-center py-3 mt-4 sm:mt-6 shadow-sm"
            >
              <FcGoogle className="h-6 w-6 mr-2" aria-hidden="true" />
              Log in with Google
            </button>
          </form>
        </div>
        <button
          type="button"
          className="absolute top-3 right-3 flex p-2 rounded-md"
          onClick={onClose}>
          <span className="sr-only">Dismiss</span>
          <FiX className="h-6 w-6" aria-hidden="true" />
        </button>
      </div>
    </div>
    <Modal
      open={showRegistrationModal}
      title="Could not log you in"
      onClose={onClose}
      zIndex="z-20"
    >
      <div className="mt-2">
        <p className="mb-2">Check your email or try another login method.</p>
        <button
          className="c-btn-primary"
          onClick={() => setShowRegistrationModal(false)}
        >
          OK
        </button>
      </div>
    </Modal>
  </>);
};