import { useQueryClient } from "react-query";
import { useMutation } from "react-query";
import { movePoint } from "../data/pointReducer";
import { useSupabaseClient } from "@supabase/auth-helpers-react";

export const useMoveListMutation = (queryKey) => {
  const queryClient = useQueryClient();
  const supabase = useSupabaseClient();

  const setListIndex = async (query) => {
    const { error } = await supabase
      .rpc('set_list_index', {
        list_id: query.list.id,
        new_index: query.index,
      })

    if (error) {
      console.log('error', error)
      throw new Error(error.message)
    }
  }

  const mutation = useMutation(setListIndex, {
    onMutate: async ({ previousBoard }) => {
      await queryClient.cancelQueries(queryKey);

      return { previousBoard };
    },
    onError: async (_err, _newCard, context) => {
      console.log("error");
      queryClient.setQueryData(queryKey, context.previousBoard);
    },
  });

  return {
    ...mutation,
    mutate: (props) => {
      // for some reason the mutate function is called after some small time,
      // but the draggable API requires the model to be updated immediately.
      // hence we need to update the model a bit earlier.
      const { list, index } = props;
      const previousBoard = queryClient.getQueryData(queryKey);

      queryClient.setQueryData(queryKey, (data) => {
        return movePoint(data, { point: list, index });
      });

      return mutation.mutate({
        ...props,
        previousBoard,
      });
    },
  };
};
