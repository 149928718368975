import { useEffect, useRef, useState, useCallback } from "react";
import { useDrag } from "@use-gesture/react";
import { PointComponent } from "./Point";
import { DragDropContext } from "react-beautiful-dnd";
import { Droppable } from "react-beautiful-dnd";
import { useAddListMutation } from "../hooks/board/useAddListMutation";
import { useMoveCardMutation } from "../hooks/card/useMoveCardMutation";
import { useMoveListMutation } from "../hooks/list/useMoveListMutation";
import { useDevice } from "../hooks/useDevice";

export const Timeline = ({ board }) => {
  const { id: boardId } = board;
  const queryKey = ["board", { id: boardId }];
  const { mutate: mutateAddList } = useAddListMutation(queryKey);
  const { mutate: mutateMoveList } = useMoveListMutation(queryKey);
  const { mutate: mutateMoveCard } = useMoveCardMutation(queryKey);
  const { isMobile, breakpoint } = useDevice();

  const onDragEnd = useCallback(
    (result, provided) => {
      const { destination, draggableId } = result;
      if (!destination) {
        return;
      }
      const index = destination.index;
      switch (result.type) {
        case "point":
          const listId = parseInt(draggableId.match(/point-([0-9]+)/)[1]);
          const list = board.lists.find((l) => l.id === listId);

          mutateMoveList({
            boardId,
            list,
            index,
          });
          break;
        case "section":
          const parentListId = parseInt(
            destination.droppableId.match(/point-([0-9]+)/)[1]
          );
          const cardId = parseInt(draggableId.match(/section-([0-9]+)/)[1]);
          const card = board.lists.reduce(
            (prev, cur) => prev || cur.cards.find((c) => c.id === cardId),
            null
          );

          mutateMoveCard({
            listId: parentListId,
            card,
            index,
          });
          break;
        default:
          console.error("Invalid dnd type");
          break;
      }
    },
    [board, boardId, queryKey]
  );

  const sensorAPIRef = useRef(null);
  const [newPointText, setNewPointText] = useState("");
  const scrollEl = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const scroll = useRef(0);
  const scrollOrigin = useRef(0);
  const onScroll = (e) => {
    scroll.current = e.target.scrollLeft;
    if (!isDragging) {
      scrollOrigin.current = e.target.scrollLeft;
    }
  };

  // Drag
  // const bind = useDrag(({ down, movement: [mx, my] }) => {
  //   if (isDragging !== down) {
  //     setIsDragging(down);
  //   }
  //   if (down) {
  //     if (scrollEl.current) {
  //       scrollEl.current.scrollLeft = scrollOrigin.current - mx;
  //     }
  //   } else {
  //     scrollOrigin.current = scroll.current;
  //   }
  // });

  function lift(obj) {
    let id = null;
    if (typeof obj === "string") {
      id = obj;
    } else {
      // manual movement
      id = obj.id;
      const { parentListId, last } = obj;
      const card = board.lists.reduce(
        (prev, cur) => prev || cur.cards.find((c) => c.id === id),
        null
      );
      const list = board.lists.find((l) => l.id === parentListId);
      const index = last ? list.cards.length : 0;

      mutateMoveCard({
        listId: parentListId,
        card,
        index,
      });
      return;
    }

    // use dnd api
    if (isDragging) {
      return null;
    }

    const api = sensorAPIRef.current;

    if (!api) {
      console.warn("unable to find sensor api");
      return null;
    }

    const preDrag = api.tryGetLock(id, () => { });

    if (!preDrag) {
      console.log("unable to start capturing");
      return null;
    }

    return preDrag.snapLift();
  }

  useEffect(() => {
    scroll.current = scrollEl.current?.scrollLeft ?? 0;
    scrollOrigin.current = scroll.current;
  }, [scrollEl]);

  const onNewPointSubmit = (e) => {
    if (e.nativeEvent.which !== 13 || !newPointText) {
      return;
    }

    mutateAddList({
      board_id: boardId,
      name: newPointText,
      index: board.lists.length,
    });
    setNewPointText("");
  };

  // TODO: read new format
  // const points = board.points.map((id) => ({
  //   ...board.pointsById[id],
  //   sections: board.pointsById[id].sections?.map((id) => ({
  //     ...board.sectionsById[id],
  //     focus: board.focusedSection === id,
  //   })),
  // }));

  return (
    <DragDropContext
      onDragEnd={onDragEnd}
      sensors={[
        (api) => {
          sensorAPIRef.current = api;
        },
      ]}
    >
      <Droppable droppableId={`timeline`} type="point" direction={breakpoint === "small" ? "vertical" : "horizontal"}>
        {(provided, snapshot) => (
          <div
            className={`flex-1 sm:overflow-auto overscroll-auto ${ !isMobile ? 'touch-none' : ''}`}
            ref={provided.innerRef}
          >
            <div
              className="flex flex-col sm:flex-row px-4 sm:h-full pb-10"
              ref={scrollEl}
              onScroll={onScroll}
            >
              {board.lists.map((point, index) => (
                <PointComponent
                  key={point.id}
                  point={point}
                  index={index}
                  previousListId={index > 0 ? board.lists[index - 1].id : null}
                  nextListId={index < board.lists.length - 1 ? board.lists[index + 1].id : null}
                  lift={lift}
                />
              ))}
              {provided.placeholder}
              <div className="flex flex-col">
                <div className="w-full sm:w-80 mb-12 sm:mb-0 sm:sticky top-0 bg-slate-100">
                  <input
                    className="c-input"
                    type="text"
                    placeholder="Add a stack of cards..."
                    value={newPointText}
                    onChange={(e) => setNewPointText(e.target.value)}
                    onKeyDown={onNewPointSubmit}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};
