import { useQueryClient } from "react-query";
import { updateSection } from "../data/sectionReducer";
import { useMutation } from "react-query";
import { useSupabaseClient } from "@supabase/auth-helpers-react";
import { CARD_QUERY } from "../../util/queries";

export const useConvertCardToBoardMutation = (queryKey) => {
  const queryClient = useQueryClient();
  const supabase = useSupabaseClient();

  const convertCardToBoard = async (query) => {
    const { createError } = await supabase
      .rpc('convert_card_to_board', {
        card_id: query.id,
      })

    if (createError) {
      console.log('error', createError)
      throw new Error(createError.message)
    }

    const { data: card, error } = await supabase
      .from('card')
      .select(CARD_QUERY)
      .single()
      .eq('id', query.id)

    if (error) {
      console.log('error', error)
      throw new Error(error.message)
    }

    return card
  }

  return useMutation(convertCardToBoard, {
    onMutate: async () => {
      await queryClient.cancelQueries(queryKey);

      const previousBoard = queryClient.getQueryData(queryKey);

      return { previousBoard };
    },
    onSuccess: async (newCard, variables, context) => {
      queryClient.setQueryData(queryKey, (data) =>
        updateSection(data, { id: newCard.id }, newCard)
      );
    },
    onError: async (_err, _newCard, context) => {
      queryClient.setQueryData(queryKey, context.previousBoard);
    },
    onSettled: () => {
      // queryClient.invalidateQueries('board')
    },
  });
};
