import { QueryClientProvider } from "react-query";
import { Header } from "../components/Header";
import { Outlet } from "react-router-dom";
import { CookieConsent } from "../components/CookieConsent";
import { CookieProvider } from "../util/CookieContext";
import { SessionContextProvider } from "@supabase/auth-helpers-react";
import { supabase } from "../util/supabaseClient";

export const Root = ({ queryClient }) => {
  return (
    <div className="flex flex-col sm:h-full min-h-full overflow-hidden bg-slate-100">
      <QueryClientProvider client={queryClient}>
        <SessionContextProvider supabaseClient={supabase}>
          <CookieProvider>
            <Header />
            <Outlet />
            <CookieConsent />
          </CookieProvider>
        </SessionContextProvider>
      </QueryClientProvider>
    </div>
  );
};
