import { useCallback, useEffect, useRef, useState } from "react";
import { FiMoreHorizontal, FiTrash2, FiPlus, FiArrowUp, FiArrowDown } from "react-icons/fi";
import { Droppable, Draggable } from "react-beautiful-dnd";
import { Modal } from "./Modal";
import { SectionWrapper } from "./SectionWrapper";
import { useBoard } from "../hooks/useBoard";
import { useAddCardMutation } from "../hooks/list/useAddCardMutation";
import { useDeleteListMutation } from "../hooks/list/useDeleteListMutation";
import { useUpdateListMutation } from "../hooks/list/useUpdateListMutation";
import { useDevice } from "../hooks/useDevice";

export const PointComponent = ({ point, index, previousListId, nextListId, lift }) => {
  const { board: { id: boardId } } = useBoard();
  const queryKey = ["board", { id: boardId }];
  const { mutate: mutateAddCard } = useAddCardMutation(queryKey);
  const { mutate: mutateDeleteList } = useDeleteListMutation(queryKey);
  const { mutate: mutateUpdateList } = useUpdateListMutation(queryKey);
  const { breakpoint } = useDevice();

  const inputRef = useRef(null);
  const [editName, setEditName] = useState(false);
  const [newPointText, setNewPointText] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const showMoveOptions = breakpoint === "small";

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  };

  useEffect(() => {
    setNewPointText(point.name || "");
  }, [point.name]);

  const newSection = () => {
    mutateAddCard({ list_id: point.id, text: "", index: point.cards.length });
    // addSection({ pointId: point.id, focus: true })
  };

  const movePointLeft = useCallback(() => {
    // movePoint({
    //   id: point.id,
    //   index: index - 1,
    // });
  }, [point, index]);

  const movePointRight = useCallback(() => {
    // movePoint({
    //   id: point.id,
    //   index: index + 1,
    // });
  }, [point, index]);

  const cancelEditName = () => {
    setNewPointText(point.name || "");
    setEditName(false);
    inputRef.current?.blur();
  };

  const onNewPointSubmit = (e) => {
    if (e.nativeEvent.key === "Escape") {
      cancelEditName();
      return;
    }
    if (e.nativeEvent.key !== "Enter" || !newPointText) {
      return;
    }

    mutateUpdateList({
      id: point.id,
      name: newPointText,
    });
    setEditName(false);
    inputRef.current?.blur();
  };

  const moveUp = () => {
    const drag = lift(`point-${point.id}`);
    drag?.moveUp();
    drag?.drop();
  }

  const moveDown = () => {
    const drag = lift(`point-${point.id}`);
    drag?.moveDown();
    drag?.drop();
  }

  return (
    <>
      <Draggable
        draggableId={`point-${point.id}`}
        index={index}
        type="point"
      >
        {(provided, snapshot) => (
          <div
            className="bg-slate-100 rounded-md px-1 pb-1 sm:mr-2"
            ref={provided.innerRef}
            {...provided.draggableProps}
          >
            <div className="flex items-center justify-between mb-2 sticky top-0 z-10 bg-slate-100">
              <input
                type="text"
                ref={inputRef}
                className={editName ? "c-input" : "bg-transparent"}
                style={editName ? {} : { paddingLeft: "0" }}
                placeholder="New point"
                value={newPointText}
                onChange={(e) => setNewPointText(e.target.value)}
                onKeyDown={onNewPointSubmit}
                onFocus={() => setEditName(true)}
                onBlur={cancelEditName}
              />
              <div className="flex justify-end flex-1 opacity-30 hover:opacity-100">
                <div
                  className="hidden sm:flex items-center justify-center w-6 h-6 rounded-sm hover:bg-white cursor-grab"
                  {...provided.dragHandleProps}
                >
                  <FiMoreHorizontal />
                </div>
                { showMoveOptions && previousListId && (
                  <button
                    className="sm:hidden flex items-center justify-center w-6 h-6 rounded-sm"
                    onClick={moveUp}
                  >
                    <FiArrowUp />
                  </button>
                )}
                { showMoveOptions && nextListId && (
                  <button
                    className="sm:hidden flex items-center justify-center w-6 h-6 rounded-sm"
                    onClick={moveDown}
                  >
                    <FiArrowDown />
                  </button>
                )}
                <button
                  className="flex items-center justify-center w-6 h-6 rounded-sm hover:bg-white"
                  onClick={() => setShowDeleteModal(true)}
                >
                  <FiTrash2 />
                </button>
              </div>
            </div>
            <Droppable droppableId={`point-${point.id}`} type="section" direction={"vertical"}>
              {(provided, snapshot) => (
                <div
                  className="flex flex-col w-full sm:w-80 pb-10"
                  ref={provided.innerRef}
                >
                  {point.cards.map((section, index) => (
                    <SectionWrapper
                      key={section.id}
                      index={index}
                      section={section}
                      lift={lift}
                      previousListId={previousListId}
                      nextListId={nextListId}
                      isFirst={index === 0}
                      isLast={index === point.cards.length - 1}
                    />
                  ))}
                  {provided.placeholder}
                  <button
                    className={`c-btn-w-icon ${point.cards.length ? "mt-2" : ""
                      }`}
                    onClick={newSection}
                  >
                    <FiPlus />
                    Add card
                  </button>
                </div>
              )}
            </Droppable>
            {provided.placeholder}
          </div>
        )}
      </Draggable>
      <Modal
        open={showDeleteModal}
        title="Delete stack?"
        onClose={closeDeleteModal}
      >
        <div className="mt-4 flex">
          <button className="c-btn-secondary mr-2" onClick={closeDeleteModal}>
            No
          </button>
          <button
            className="c-btn-primary"
            onClick={() => {
              closeDeleteModal();
              mutateDeleteList({ id: point.id });
            }}
          >
            Yes
          </button>
        </div>
      </Modal>
    </>
  );
};
