import { createContext, useState } from "react";

const COOKIE_DISMISSED_STORAGE_KEY = "flo-sho-cookie-dismissed";

export const removeDismissed = () => {
  window.localStorage.removeItem(COOKIE_DISMISSED_STORAGE_KEY);
};

export const saveDismissed = (value) => {
  window.localStorage.setItem(COOKIE_DISMISSED_STORAGE_KEY, value);
};

export const getSavedDismissed = () => {
  try {
    const dismissed = window.localStorage.getItem(COOKIE_DISMISSED_STORAGE_KEY);
    return dismissed === 'true';
  } catch (e) { }
  return null;
};

export const CookieContext = createContext({
  dismissed: false,
  setDismissed: () => { },
});

export const CookieProvider = ({ children }) => {
  const [dismissed, setDismissed] = useState(getSavedDismissed());

  const dismiss = () => {
    saveDismissed(true);
    setDismissed(true);
  };

  return (<>
    <CookieContext.Provider value={{ dismissed, dismiss }}>
      {children}
    </CookieContext.Provider>
  </>);
};