import { Fragment, useEffect, useRef, useState } from 'react';
import { Combobox, Transition } from '@headlessui/react';
import { useBoard } from '../hooks/useBoard';
import { getBoardUrl } from "../routes/Board";
import { FiChevronDown } from 'react-icons/fi';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSupabaseClient } from '@supabase/auth-helpers-react';
import useDebounce from '../hooks/useDebounce';

const createSearchArray = (searchString) => {
  return searchString
    .trim()
    .split(/[\s,\t,\n]+/) // split and remove more than 1 space
    .filter((s) => s !== '') // remove empty strings
};

export const MultiSearch = ({ open, openChange }) => {
  const [results, setResults] = useState([]);
  const inputRef = useRef();
  const { board: selected } = useBoard();
  const [query, setQuery] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const supabase = useSupabaseClient();
  const [isSearching, setIsSearching] = useState(false);

  const triggerSearch = async () => {
    if (!open || query === '') {
      setResults([]);
      return;
    }

    setIsSearching(true);
    const searchWords = createSearchArray(query);
    const searchQuery = `${searchWords.map((word) => `${word}:*`).join(' | ')}`;

    const { data: boardData, error: boardError } = await supabase
      .from('board')
      .select(`
        id,
        content:name,
        name
      `)
      .limit(3)
      .textSearch('fts', searchQuery);

    const { data: listData, error: listError } = await supabase
      .from('list')
      .select(`
        id,
        content:name,
        board (
          id,
          name
        )
      `)
      .limit(3)
      .textSearch('fts', searchQuery);

    const { data: cardData, error: cardError } = await supabase
      .from('filtered_cards')
      .select(`
        id,
        content:text,
        list (
          board (
            id,
            name
          )
        )
      `)
      .limit(3)
      .textSearch('fts', searchQuery);

    setIsSearching(false);

    if (cardError || listError || boardError) {
      console.log('error', cardError || listError || boardError)
      throw new Error(cardError || listError || boardError)
    } else {
      const regex = searchWords.map((word) => new RegExp(word, 'gi'));
      const results = [...boardData, ...listData, ...cardData].map((row) => {
        // limit row.text to 100 characters, with the search term in the beginning with 10 characters before
        
        const text = row.content;
        const index = text.toLowerCase().indexOf(searchWords[0].toLowerCase());
        const start = Math.max(0, index - 10);
        const end = Math.min(text.length, index + 100);
        const textSnippet = `${start > 0 ? '... ' : ''}${text.substring(start, end)}${end < text.length ? '...' : ''}`;

        const highlightedText = searchWords.reduce((text, word) => {
          return text.replace(regex[searchWords.indexOf(word)], '<span class="bg-yellow-200">$&</span>');
        }, textSnippet);

        return {
          ...row,
          highlightedText,
          type: row.list ? 'card' : (row.board ? 'list' : 'board'),
        };
      });
      setResults(results);
    }
  }

  const debouncedTriggerSearch = useDebounce(triggerSearch, 300, [query]);

  const onSelect = (entry) => {
    const board = entry.type === 'card' ? entry.list.board : (entry.type === 'list' ? entry.board : entry);
    navigate(getBoardUrl(board));
    openChange(false);
  };

  useEffect(() => {
    if (open) {
      triggerSearch();
    } else {
      setQuery('');
      setResults([]);
    }

    const onKeydown = (e) => {
      // ctrl/cmd + k
      if (e.key === 'k' && (e.ctrlKey || e.metaKey) && !open) {
        openChange(true);
        e.stopPropagation();
        e.preventDefault();
      }
      if (e.key === 'Escape' && open) {
        openChange(false);
      }
    };

    document.addEventListener('keydown', onKeydown);

    return () => {
      document.removeEventListener('keydown', onKeydown);
    };
  }, [open]);

  useEffect(() => {
    if (selected) {
      openChange(false);
    }
  }, [selected]);

  useEffect(() => {
    if (open) {
      inputRef.current?.focus();
    }
  }, [open, inputRef]);

  useEffect(() => {
    debouncedTriggerSearch();
  }, [open, query]);

  useEffect(() => {
    openChange(false);
  }, [location]);

  return (<>
    {open && (<div className="w-72 relative">
      <Combobox value={selected} onChange={onSelect} >{({ open: comboOpen }) => (<>
        <div className="relative">
          <div className="relative w-full text-left">
            <Combobox.Input
              className="w-full border-none c-input"
              placeholder='Search (ESC to close)'
              value={query}
              onChange={(event) => setQuery(event.target.value)}
              ref={inputRef}
            />
            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
              <FiChevronDown className="w-5 h-5 text-gray-400" aria-hidden="true" />
            </Combobox.Button>
          </div>
          <Transition
            show
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => setQuery('')}
          >
            <Combobox.Options static className={results?.length > 0 || query !== '' ? 'absolute w-full px-1 py-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm' : ''}>
              {results?.length === 0 && query !== '' || isSearching ? (
                <div className="cursor-default select-none relative py-2 px-4 text-gray-700">
                  { isSearching ? 'Searching...' : 'Nothing found.' }
                </div>
              ) : (
                results?.map((entry) => (
                  <Combobox.Option
                    key={entry.id}
                    className={({ active }) =>
                      `${active && 'bg-gray-200'} group flex rounded-md items-center w-full px-2 py-2 text-sm`
                    }
                    value={entry}
                  >
                    {({ selected, active }) => (
                      <>
                        { entry.type !== 'card' && (
                        <span className="rounded bg-slate-100 px-2 mr-1">{entry.type}</span>
                        )}
                        <span
                          className={`block w-full break-words ${selected ? 'font-medium' : 'font-normal'
                            }`}
                          dangerouslySetInnerHTML={{ __html: entry.highlightedText }}
                        />
                      </>
                    )}
                  </Combobox.Option>
                ))
              )}
            </Combobox.Options>
          </Transition>
        </div>
      </>)}</Combobox>
    </div>)}
  </>);
};