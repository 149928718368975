export const H1 = ({ children }) => (
    <h1 className="text-2xl font-bold text-gray-900 mb-4">{children}</h1>
);

export const H2 = ({ children }) => (
    <h2 className="text-xl font-bold text-gray-900 mt-4 mb-2">{children}</h2>
);

export const H3 = ({ children }) => (
    <h3 className="text-lg font-bold text-gray-900 mb-2">{children}</h3>
);

export const H4 = ({ children }) => (
    <h4 className="text-base font-bold text-gray-900 mb-2">{children}</h4>
);

export const P = ({ children }) => (
    <p className="text-base text-gray-900 mb-2">{children}</p>
);

export const UL = ({ children }) => (
    <ul className="list-disc pl-5">{children}</ul>
);

export const LI = ({ children }) => (
    <li className="mb-2">{children}</li>
);

export const A = ({ children, href }) => (
    <a href={href} className="underline text-blue-800 hover:text-blue-600 cursor-pointer">{children}</a>
);

export default { h1: H1, h2: H2, h3: H3, h4: H4, p: P, ul: UL, li: LI, a: A };