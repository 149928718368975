import { Timeline } from "./Timeline";
import { useEffect, useRef, useState } from "react";
import { useBoard } from "../hooks/useBoard";
import { Modal } from "./Modal";
import { FiChevronRight, FiTrash2 } from "react-icons/fi";
import { useDeleteBoardMutation } from "../hooks/board/useDeleteBoardMutation";
import { useUpdateBoardMutation } from "../hooks/board/useUpdateBoardMutation";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getBoardUrl } from "../routes/Board";

export const Editor = ({ data }) => {
  const { board: selectedBoard } = useBoard();
  const queryKey = ["board", { id: selectedBoard?.id }];
  const { mutate: mutateDeleteBoard } = useDeleteBoardMutation(queryKey);
  const { mutate: mutateUpdateBoard } = useUpdateBoardMutation(queryKey);
  const [board, setBoard] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { state } = useLocation();
  const navigate = useNavigate();

  const inputRef = useRef(null);
  const [editBoardName, setEditBoardName] = useState(false);
  const [newBoardName, setNewBoardName] = useState("");
  const [boardNameWidth, setBoardNameWidth] = useState(10);

  useEffect(() => {
    if (!data) {
      setBoard(null);
      return;
    }
    setBoard(data);
  }, [data]);

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const deleteBoard = () => {
    mutateDeleteBoard({
      id: board.id,
    });
  };

  useEffect(() => {
    setNewBoardName(board?.name || "");
  }, [board?.name]);

  useEffect(() => {
    setBoardNameWidth(Math.max(newBoardName.length, 10));
  }, [newBoardName]);

  const cancelEditBoardName = () => {
    if (!editBoardName) {
      return;
    }
    setNewBoardName(board.name || "");
    setEditBoardName(false);
    inputRef.current?.blur();
  };

  const onNewBoardNameChange = (e) => {
    setNewBoardName(e.target.value);
  };

  const onNewBoardNameSubmit = async (e) => {
    if (e.nativeEvent.key === "Escape") {
      cancelEditBoardName();
      return;
    }
    if (e.nativeEvent.key !== "Enter" || !newBoardName) {
      return;
    }

    setEditBoardName(false);

    await mutateUpdateBoard({
      id: board.id,
      name: newBoardName,
    });

    inputRef.current?.blur();

    document.title = newBoardName;
  };

  useEffect(() => {
    if (state?.isNew && inputRef.current) {
      inputRef.current.select();
    }
  }, [state, inputRef.current]);

  useEffect(() => {
    if (board) {
      document.title = board.name;
    }
  }, [board]);

  useEffect(() => {
    const onKeydown = (e) => {
      if (e.key === 'p' && board?.parent) {
        navigate(getBoardUrl(board.parent.card.list.board));
        e.stopPropagation();
        e.preventDefault();
      }
    };

    document.addEventListener('keydown', onKeydown);

    return () => {
      document.removeEventListener('keydown', onKeydown);
    };
  }, [board]);

  return selectedBoard && board ? (
    <>
      <div className="flex items-center px-5 py-2 animate-appear">
        {board.parent && (
          <>
            <Link to={getBoardUrl(board.parent.card.list.board)}
              className="text-lg text-slate-500 hover:text-black hover:underline"
            >
              {board.parent.card.list.board.name}
            </Link>
            <FiChevronRight className="mx-2" />
          </>
        )}
        {/* <div className="mr-2 text-lg font-semibold">{board.name}</div> */}
        <input
          type="text"
          ref={inputRef}
          className={`mr-2 text-lg font-semibold ${editBoardName ? "c-input" : "bg-transparent"
            }`}
          style={{
            width: `${boardNameWidth + 1}ch`,
            ...(editBoardName ? {} : { paddingLeft: "0" }),
          }}
          placeholder="Board name"
          value={newBoardName}
          onChange={onNewBoardNameChange}
          onKeyDown={onNewBoardNameSubmit}
          onFocus={() => setEditBoardName(true)}
          onBlur={cancelEditBoardName}
        />
        <button
          className="c-icon-btn opacity-40 hover:opacity-100"
          onClick={() => setShowDeleteModal(true)}
        >
          <FiTrash2 />
        </button>
      </div>
      <Timeline board={board} />
      <Modal
        open={showDeleteModal}
        title="Delete board?"
        onClose={closeDeleteModal}
      >
        <div className="mt-4 flex">
          <button className="c-btn-secondary mr-2" onClick={closeDeleteModal}>
            No
          </button>
          <button
            className="c-btn-primary"
            onClick={() => {
              closeDeleteModal();
              deleteBoard();
            }}
          >
            Yes
          </button>
        </div>
      </Modal>
    </>
  ) : null;
};
