import { useQueryClient } from "react-query";
import { addSection, updateSection } from "../data/sectionReducer";
import { useMutation } from "react-query";
import { useSupabaseClient } from "@supabase/auth-helpers-react";

export const useAddCardMutation = (queryKey) => {
  const queryClient = useQueryClient();
  const supabase = useSupabaseClient();

  const createCard = async (query) => {
    const { data: card, error } = await supabase
      .from('card')
      .insert(query)
      .select()
      .single()

    if (error) {
      console.log('error', error)
      throw new Error(error.message)
    } else {
      return card
    }
  }

  return useMutation(createCard, {
    onMutate: async (newCard) => {
      await queryClient.cancelQueries(queryKey);

      const previousBoard = queryClient.getQueryData(queryKey);

      const tmpId = `tmp_${Date.now()}`;

      queryClient.setQueryData(queryKey, (data) =>
        addSection(data, {
          pointId: newCard.list_id,
          section: {
            id: tmpId,
            text: newCard.text,
            color: newCard.color,
          },
        })
      );

      return { previousBoard, tmpId };
    },
    onSuccess: async (newCard, variables, context) => {
      queryClient.setQueryData(queryKey, (data) =>
        updateSection(data, { id: context.tmpId }, newCard)
      );
    },
    onError: async (_err, _newCard, context) => {
      queryClient.setQueryData(queryKey, context.previousBoard);
    },
    onSettled: () => {
      // queryClient.invalidateQueries('board')
    },
  });
};
