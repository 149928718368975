import { useQueryClient } from "react-query";
import { useMutation } from "react-query";
import { updateSection } from "../data/sectionReducer";
import { useSupabaseClient, useUser } from "@supabase/auth-helpers-react";

export const useUnfavoriteCardMutation = (queryKey) => {
  const queryClient = useQueryClient();
  const supabase = useSupabaseClient();
  const user = useUser();

  const unfavoriteCard = async (query) => {
    const { error } = await supabase
      .from('card_favorite')
      .delete()
      .match({
        id: query.id,
        user_id: user.id,
      });

    if (error) {
      console.log('error', error)
      throw new Error(error.message)
    }
  }

  return useMutation(unfavoriteCard, {
    onMutate: async (newCard) => {
      await queryClient.cancelQueries(queryKey);

      const previousBoard = queryClient.getQueryData(queryKey);

      if (queryKey && queryKey[0] === "board") {
        queryClient.setQueryData(queryKey, (data) =>
          updateSection(data, { id: newCard.id }, {
            ...newCard,
            favorite: false,
          })
        );
      }
      queryClient.setQueryData(["favorites", { user_id: user.id }], (data) => data.filter((card) => card.id !== newCard.id));

      return { previousBoard };
    },
    onSuccess: async (newCard, variables, context) => {
      if (queryKey && queryKey[0] === "board") {
        queryClient.setQueryData(queryKey, (data) =>
          updateSection(data, { id: newCard.id }, newCard)
        );
      }
    },
    onError: async (_err, _newCard, context) => {
      queryClient.setQueryData(queryKey, context.previousBoard);
    },
  });
};
