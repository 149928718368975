import ReactMarkdown from "react-markdown";
import { useLoaderData } from "react-router-dom";
import markdownPath from "./flosho-tos.md";
import components from "../../components/markdown";

export const loader = async () => {
    return await fetch(markdownPath).then(res => res.text());
};

export const TermsOfService = () => {
    const markdown = useLoaderData();
    return (<ReactMarkdown components={components}>
        {markdown}
    </ReactMarkdown>);
};