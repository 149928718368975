import { Link, useParams } from "react-router-dom";
import { PolicyAgreement } from "../../components/PolicyAgreement";

export const Register = () => {
  const { code } = useParams();

  return (
    <div className="flex flex-col items-center justify-center h-full">
      <div className="bg-white shadow-lg rounded-lg p-8 flex flex-col max-w-md">
        <h1 className="text-2xl font-bold mb-4">Register</h1>
        <form action={`${process.env.REACT_APP_API_URI}/oauth/finalize`}>
          <input type="hidden" name="code" value={encodeURIComponent(code)} />
          <PolicyAgreement />
          <div className="flex justify-end pt-2 gap-2">
            <Link to="/" className="text-xl px-6 py-3 rounded-md hover:bg-gray-100 text-black">Cancel</Link>
            <input type="submit" className="text-xl px-6 py-3 rounded-md bg-slate-700 hover:bg-slate-500 text-white" value="Continue" />
          </div>
        </form>
      </div>
    </div>
  )
}