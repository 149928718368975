import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { FiFileText, FiHelpCircle, FiLogOut, FiMenu } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { useUser } from "@supabase/auth-helpers-react";
import { supabase } from "../util/supabaseClient";

export const HeaderMenu = () => {
  const user = useUser();
  const navigate = useNavigate();

  const signOut = async () => {
    const { error } = await supabase.auth.signOut()

    if (error) {
      console.log(error)
    } else {
      navigate('/');
    }
  }

  const items = [
    ...(user ? [{
      icon: <FiLogOut />,
      text: "Log out",
      action: () => {
        signOut();
      },
    }] : []),
    {
      icon: <FiFileText />,
      text: "Policies",
      action: () => {
        navigate('/policies');
      },
    },
    {
      icon: <FiHelpCircle />,
      text: "About",
      action: () => {
        window.location.href = '/about/';
      },
    },
  ];

  return (
    <>
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button className="c-card-btn flex items-center justify-center w-6 h-6 rounded-sm hover:bg-zinc-100">
            <FiMenu />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items
            className={`z-10 absolute right-0 w-56 bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none origin-top-right`}
          >
            {items.map(({ text, icon, action }, index) => (
              <div className="px-1 py-1 relative" key={index}>
                <Menu.Item>
                  {({ active }) => (
                    <button
                      className={`${active && "bg-gray-200"
                        } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                      onClick={action}
                    >
                      {icon && (
                        <div className="flex justify-center w-4 mr-2">
                          {icon}
                        </div>
                      )}
                      {text}
                    </button>
                  )}
                </Menu.Item>
              </div>
            ))}
          </Menu.Items>
        </Transition>
      </Menu>
    </>
  );
};
