import { useCallback, useEffect, useState } from "react";
import { FiX } from "react-icons/fi";
import { FcGoogle } from "react-icons/fc";
import { useSupabaseClient, useUser } from "@supabase/auth-helpers-react";
import { Modal } from "./Modal";
import { PolicyAgreementNoCheck } from "./PolicyAgreementNoCheck";

export const RegisterForm = ({ onClose }) => {
  const supabase = useSupabaseClient();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [registerError, setRegisterError] = useState(null);
  const [showRegistrationSuccessModal, setShowRegistrationSuccessModal] = useState(false);
  const [password, setPassword] = useState("");
  const [visible, setVisible] = useState(false);

  const user = useUser();

  const register = useCallback(async (e) => {
    e.preventDefault();
    try {
      const { data, error } = await supabase.auth.signUp({
        email,
        password,
        options: {
          data: {
            name,
          }
        },
      });

      if (error) {
        setRegisterError(error.message);
      } else {
        if (data.user && !data.session) {
          setShowRegistrationSuccessModal(true);
        } else {
          setRegisterError('Failed to register');
        }
      }
    } catch (error) {
      console.log(error);
      setRegistrationError(error.message);
    }
  }, [email, password, name, supabase]);

  const signInWithGoogle = useCallback(async () => {
    const { error } = await supabase.auth.signInWithOAuth({
      provider: 'google',
      options: {
        redirectTo: process.env.REACT_APP_APP_URL,
      },
    })

    if (error) {
      console.log(error)
    }
  }, [supabase]);

  useEffect(() => {
    if (user) {
      onClose();
    }
  }, [user, onClose]);

  useEffect(() => {
    setVisible(true);
  }, []);

  return (<>
    <div className={`overflow-hidden transition-all ease-in-out duration-300 ${visible ? 'max-h-[30rem]' : 'max-h-0'}`}>
      <div className="relative p-6 bg-slate-100">
        <h2 className="text-xl font-bold mb-2">Register</h2>
        <div className="flex flex-col sm:flex-row max-w-lg">
          <form onSubmit={register}>
            <label htmlFor="register-name">Name *</label>
            <input
              className="c-input"
              id="register-name"
              type="text"
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              autoFocus={true}
              required={true}
            />
            <label htmlFor="register-email">Email *</label>
            <input
              className="c-input"
              id="register-email"
              type="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required={true}
            />
            <label htmlFor="register-password">Password *</label>
            <input
              className="c-input mb-4"
              id="register-password"
              type="password"
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required={true}
            />
            <PolicyAgreementNoCheck />
            {registerError && (
              <p className="text-red-500 mb-2">{registerError}</p>
            )}
            <div className="flex items-center">
              <button type="submit" className="c-btn c-btn-primary h-12">
                Register
              </button>
              <span className="px-4">or</span>
              <button
                type="button"
                onClick={signInWithGoogle}
                className="c-btn inline-flex items-center py-3 shadow-sm"
              >
                <FcGoogle className="h-6 w-6 mr-2" aria-hidden="true" />
                Register with Google
              </button>
            </div>
          </form>
        </div>
        <button
          type="button"
          className="absolute top-3 right-3 flex p-2 rounded-md"
          onClick={onClose}>
          <span className="sr-only">Dismiss</span>
          <FiX className="h-6 w-6" aria-hidden="true" />
        </button>
      </div>
    </div>
    <Modal
      open={showRegistrationSuccessModal}
      title="Registration successful"
      onClose={() => {}}
      zIndex="z-20"
    >
      <div className="mt-2">
        <p className="mb-1">Please check your email to continue.</p>
        <p><a href="/" className="link">Return to home</a></p>
      </div>
    </Modal>
  </>);
};