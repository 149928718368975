export const PolicyAgreementNoCheck = () => (
  <p className="mb-4">
    By continuing you are agreeing to the{' '}
    <a className="link" href="/policies/eula" target="_blank" rel="noopener noreferrer">
        End User License Agreement
    </a>
    {' '}and the{' '}
    <a className="link" href="/policies/terms-of-service" target="_blank" rel="noopener noreferrer">
        Terms of Service
    </a>.
  </p>
);