import { useContext } from "react";
import { FiX } from "react-icons/fi";
import { BiCookie } from "react-icons/bi";
import { Link } from "react-router-dom";
import { CookieContext } from "../util/CookieContext";

export const CookieConsent = () => {
  const { dismissed, dismiss } = useContext(CookieContext);

  if (dismissed) {
    return null;
  }

  return (<>
    <div className="fixed bottom-0 inset-x-0 pb-2 sm:pb-5">
      <div className="max-w-screen-xl mx-auto px-2 sm:px-6 lg:px-8">
        <div className="p-2 rounded-lg bg-slate-800 shadow-lg sm:p-4">
          <div className="flex items-center justify-between flex-wrap">
            <div className="w-0 flex-1 flex items-center">
              <span className="flex p-2 rounded-lg bg-slate-600">
                <BiCookie className="h-6 w-6 text-white" aria-hidden="true" />
              </span>
              <div className="text-white ml-4 mr-3">
                <p className="font-bold mb-1">
                  This site uses cookies.
                </p>
                <p className="font-medium hidden sm:block">
                  To learn more, check out how we use cookies and check out our privacy policy.
                </p>
              </div>
            </div>
            <div className="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
              <Link to="/policies" className="flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-slate-600 bg-white hover:bg-slate-50">
                Learn more
              </Link>
            </div>
            <div className="order-2 flex-shrink-0 sm:order-3 sm:ml-3">
              <button
                type="button"
                className="-mr-1 flex p-2 rounded-md hover:bg-slate-500 focus:outline-none focus:ring-2 focus:ring-white sm:-mr-2"
                onClick={dismiss}>
                <span className="sr-only">Dismiss</span>
                <FiX className="h-6 w-6 text-white" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>);
};