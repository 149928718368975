import { useSupabaseClient } from "@supabase/auth-helpers-react";
import { useEffect } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { Editor } from "../components/Editor";
import { BoardProvider } from "../hooks/useBoard";
import { BOARD_QUERY } from "../util/queries";

// generate a url safe slug from a name
const getNameSlug = (name) => name?.toLowerCase().replace(/[^a-z0-9]/g, '-') ?? '';

export const getBoardUrl = ({ id, name }) => `/board/${id}/${getNameSlug(name)}`;

export const Board = () => {
  const supabase = useSupabaseClient();

  const fetchBoard = async ({ queryKey }) => {
    const { id } = queryKey[1];
    const { data: board, error } = await supabase
      .from('board')
      .select(BOARD_QUERY)
      .single()
      .eq('id', id)

    if (error) {
      console.log('error', error)
      throw new Error(error)
    } else {
      // return board with ordered lists and cards
      return {
        ...board,
        lists: board.lists.sort((a, b) => a.index - b.index).map(list => ({
          ...list,
          cards: list.cards.sort((a, b) => a.index - b.index)
        })),
      }
    }
  }

  const { id, name } = useParams();
  const { data, isLoading } = useQuery(
    ['board', { id: parseInt(id) }],
    fetchBoard,
    {
      refetchOnWindowFocus: false, // TODO: turn on later
    }
  );

  useEffect(() => {
    if (!data) {
      return;
    }
    const expectedNameSlug = getNameSlug(data.name);
    if (expectedNameSlug && (!name || name !== expectedNameSlug)) {
      window.location.href = getBoardUrl(data);
    }
  }, [data]);

  if (isLoading) {
    return null;
  }

  if (!data) {
    throw new Error('Board not found');
  }

  return (<>
    <BoardProvider routeId={id}>
      <Editor data={data} />
    </BoardProvider>
  </>);
};