import { FiHome } from "react-icons/fi";
import { Link, NavLink, Outlet } from "react-router-dom";
import { CookieConsent } from "../../components/CookieConsent";
import { CookieProvider } from "../../util/CookieContext";
import { useDevice } from "../../hooks/useDevice";

export const Policies = () => {
  const { isMobile } = useDevice();
  const navClass = ({ isActive }) => `c-btn ${isActive ? 'underline' : ''}`;

  return (
    <CookieProvider>
      <div className="flex flex-col h-full overflow-hidden bg-slate-100">
        <div className="relative flex flex-shrink-0 items-center px-1 mx-1 my-1 h-10 rounded-lg z-20 bg-white shadow-sm">
          <div className="flex flex-1 justify-between items-center animate-appear px-2">
            <div className="flex items-center">
              <Link to="/"
                className="c-card-btn flex items-center justify-center w-6 h-6 rounded-sm hover:bg-zinc-100 mr-2"
              >
                <FiHome />
              </Link>
              <NavLink to="/policies/eula" className={navClass}>
                EULA
              </NavLink>
              <NavLink to="/policies/terms-of-service" className={navClass}>
                Terms of Service
              </NavLink>
              <NavLink to="/policies/privacy-policy" className={navClass}>
                Privacy Policy
              </NavLink>
              <NavLink to="/policies/cookie-policy" className={navClass}>
                Cookie Policy
              </NavLink>
            </div>
          </div>
        </div>
        <div className={`flex-1 overflow-auto overscroll-auto ${ !isMobile ? 'touch-none' : ''} px-5 py-8 animate-appear`}>
          <div className="max-w-prose mx-auto">
            <Outlet />
          </div>
        </div>
      </div>
      <CookieConsent />
    </CookieProvider>
  );
};