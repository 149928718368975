import { useCallback, useMemo, useRef } from "react";
import { colors } from "./ColorPicker";
import { SectionDisplay } from "./SectionDisplay";
import { SectionBoard } from "./SectionBoard";
import { SectionMenu } from "./SectionMenu";
import { FiColumns } from "react-icons/fi";
import { Link } from "react-router-dom";
import { getBoardUrl } from "../routes/Board";
import { useSupabaseClient, useUser } from "@supabase/auth-helpers-react";
import { FAVORITES_QUERY } from "../util/queries";
import { useInfiniteQuery, useQuery } from "react-query";
import InfiniteScroll from "react-infinite-scroller";

const formatDate = (date) => new Date(date).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });

const PAGE_SIZE = 20;

export const Dashboard = () => {
  const user = useUser();
  const supabase = useSupabaseClient();
  const scrollParent = useRef(null);

  const fetchBoards = async (page) => {
    const offset = page * PAGE_SIZE;
    const { data: boards, error } = await supabase
      .from('filtered_boards')
      .select()
      .range(offset, offset + PAGE_SIZE - 1)
      .order('created', { ascending: true })

    if (error) {
      console.log('error', error)
      throw new Error(error)
    } else {
      return boards
    }
  };

  const fetchFavorites = useCallback(async ({ queryKey }) => {
    const userId = queryKey[1].user_id;

    const { data: favorites, error } = await supabase
      .from('card_favorite')
      .select(FAVORITES_QUERY)
      .eq('user_id', userId)
      .order('added', { ascending: false })

    if (error) {
      console.log('error', error)
      throw new Error(error)
    } else {
      return favorites
    }
  }, [supabase, user])

  const { data: paginatedBoards, hasNextPage, fetchNextPage } = useInfiniteQuery({
    queryKey: ["boards"],
    queryFn: ({ pageParam = 0 }) => fetchBoards(pageParam),
    getNextPageParam: (lastPage, allPages) => (
      lastPage.length === PAGE_SIZE ? allPages.length : undefined
    ),
    refetchOnWindowFocus: false, // TODO: turn on later
    enabled: !!user,
  });

  const { data: favorites } = useQuery(["favorites", { user_id: user?.id }], fetchFavorites, {
    refetchOnWindowFocus: false, // TODO: turn on later
    enabled: !!user,
  });

  const boards = useMemo(() => (
    paginatedBoards?.pages.reduce((prev, cur) => prev.concat(cur), []) ?? []
  ), [paginatedBoards]);

  return (
    <div className="flex-1 flex flex-col sm:flex-row overflow-y-auto px-5 py-4" ref={scrollParent}>
      {user && (
        <div className="h-20">
          Search by typing Ctrl/Cmd + 'k'.
        </div>
      )}
      {user ? (
        <div className="sm:flex-1 sm:ml-8 mb-8">
          <h2 className="font-semibold mb-2">Highlights</h2>
          {favorites?.length === 0 && (
            <div className="w-full sm:w-80 flex flex-col">
              <div className="w-full px-6 py-4 mb-2 bg-slate-200 rounded-md">
                Highlight cards to show them here.
              </div>
            </div>
          )}
          {favorites?.map((card, index) => {
            const cardColor = colors.find((c) => c.name === card.color);
            return (
              <div key={card.id} className="mb-2">
                <div
                  className={`flex flex-col pb-1 rounded-md flex-shrink-0 mb-2 w-full sm:w-80 card-${cardColor?.name ?? "white"
                    } ${card.board ? "card--board" : ""}`}
                >
                  {card.board ? (
                    <SectionBoard section={card.card} />
                  ) : (
                    <SectionDisplay section={card.card} />
                  )}
                  <div className="flex px-2 pt-2 pb-1">
                    <Link to={getBoardUrl(card.parent.list.board)}
                      className="c-card-btn flex items-center flex-1 h-6 text-left px-2 rounded-sm"
                    >
                      <FiColumns className="mr-2" />
                      {card.parent.list.board.name}
                    </Link>
                    <SectionMenu
                      section={card.card}
                      queryKey={["favorites"]}
                      options={{
                        convert: false,
                        color: false,
                      }}
                    />
                  </div>
                </div>
              </div>
            );
          })}
          <h2 className="font-semibold mb-2">Your boards</h2>
          {!boards?.length ? (
            <div className="w-full sm:w-80 flex flex-col">
              <div className="w-full px-6 py-4 mb-2 bg-slate-200 rounded-md">
                You have no boards yet.
              </div>
            </div>
          ) : (
            <InfiniteScroll
              pageStart={0}
              loadMore={fetchNextPage}
              hasMore={hasNextPage}
              useWindow={false}
              getScrollParent={() => scrollParent.current}
            >
              <div className="grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 mb-4" style={{ maxWidth: "1200px" }}>
                {boards?.map((board, index) => {
                  return (
                    <div key={board.id} className="mb-2">
                      <Link
                        to={getBoardUrl(board)}
                        className="flex px-2 pt-2 pb-4 rounded-md w-full min-h-full break-all bg-white hover:bg-slate-50"
                      >
                        <FiColumns className="mt-1 mr-2 text-slate-500" />
                        <div className="flex-1">
                          <p className="text-slate-500 mb-2">{formatDate(board.created)}</p>
                          <p className="text-xl font-semibold">{board.name}</p>
                        </div>
                      </Link>
                    </div>
                  );
                })}
              </div>
            </InfiniteScroll>
          )}
        </div>
      ) : (
        <div className="flex">
          <div className="px-1 flex flex-col">
            <div className="flex flex-col pb-1 rounded-md flex-shrink-0 mb-2 w-80 card-white">
              <SectionDisplay
                section={{ text: ['# Flo Sho', '', 'A list making, note taking and project management tool.'].join('\n'), color: 'white' }}
                editable={true} />
            </div>
            <div className="flex flex-col pb-1 rounded-md flex-shrink-0 mb-2 w-80 card-white">
              <SectionDisplay
                section={{ text: ['## How it works', '', '- *Cards* can be arranged in *Stacks*', '- *Boards* can be nested', '', '  ', '', '', '', 'Cards support basic *markdown*, even [links](https://flosho.io) and images.', '__*Try it!*__'].join('\n'), color: 'white' }}
                editable={true} />
            </div>
            <div className="flex flex-col pb-1 rounded-md flex-shrink-0 mb-2 w-80 card-yellow">
              <SectionDisplay
                section={{ text: ['[About FloSho](/about/)', '', ' ', '', 'Feel free to send feedback and questions to [contact@flosho.io](mailto:contact@flosho.io).'].join('\n') }}
                editable={true} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
