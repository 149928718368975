let UNIQUE = 1;

export const PolicyAgreement = () => {
  const id = `policy-agreement-${UNIQUE += 1}`;
  const linkClass = 'underline text-blue-800 hover:text-blue-600 cursor-pointer';

  return (
    <label htmlFor={id} className="flex items-start mb-2">
      <input className="mt-1 mr-2" type="checkbox" id={id} required={true} />
      <span>
        I have read and agree to the{' '}
        <a className={linkClass} href="/policies/eula" target="_blank" rel="noopener noreferrer">
          End User License Agreement
        </a>
        {' '}and the{' '}
        <a className={linkClass} href="/policies/terms-of-service" target="_blank" rel="noopener noreferrer">
          Terms of Service
        </a>.
      </span>
    </label>
  );
};