import { useEffect, useMemo, useState } from 'react';

const SMALL_BREAKPOINT = 640;
const MEDIUM_BREAKPOINT = 1024;

export const useDevice = () => {
  const [breakpoint, setBreakpoint] = useState(() => (
    window.matchMedia(`(max-width: ${SMALL_BREAKPOINT}px)`).matches
    ? 'small'
    : window.matchMedia(`(max-width: ${MEDIUM_BREAKPOINT}px)`).matches
      ? 'medium'
      : 'large'
  ));

  const isMobile = useMemo(() => (
    ('ontouchstart' in window) ||
    (window.navigator.maxTouchPoints > 0) ||
    (window.navigator.msMaxTouchPoints > 0)
  ), []);

  useEffect(() => {
    const smallmq = window.matchMedia(`(max-width: ${SMALL_BREAKPOINT}px)`);
    const mediummq = window.matchMedia(`(max-width: ${MEDIUM_BREAKPOINT}px)`);
    const smalllistener = () => setBreakpoint(smallmq.matches ? 'small' : 'medium');
    const mediumlistener = () => setBreakpoint(mediummq.matches ? 'medium' : 'large');
    smallmq.addListener(smalllistener);
    mediummq.addListener(mediumlistener);
    return () => {
      smallmq.removeListener(smalllistener);
      mediummq.removeListener(mediumlistener);
    };
  }, []);

  return {
    isMobile,
    breakpoint,
  }
}
