export const CARD_QUERY = `
  id,
  index,
  text,
  color,
  favorite:card_favorite (*),
  board:card_board (
    board (
      id,
      name
    )
  )
`

export const BOARD_QUERY = `
  id,
  name,
  created,
  lists:list (
    id,
    index,
    name,
    cards:card (
      ${CARD_QUERY}
    )
  ),
  parent:card_board (
    card (list (board (id, name)))
  )
`

export const FAVORITES_QUERY = `
  id,
  added,
  card (
    ${CARD_QUERY}
  ),
  parent:card (
    list (board (id, name))
  )
`