import { useQueryClient } from "react-query";
import { useMutation } from "react-query";
import { useSupabaseClient } from "@supabase/auth-helpers-react";

export const useUpdateBoardMutation = (queryKey) => {
  const queryClient = useQueryClient();
  const supabase = useSupabaseClient();

  const updateBoard = async (query) => {
    const { data: board, error } = await supabase
      .from('board')
      .update(query)
      .select()
      .single()
      .eq('id', query.id)

    if (error) {
      console.log('error', error)
      throw new Error(error.message)
    } else {
      return board
    }
  }

  return useMutation(updateBoard, {
    onMutate: async (newBoard) => {
      await queryClient.cancelQueries(queryKey);

      const previousBoard = queryClient.getQueryData(queryKey);

      queryClient.setQueryData(queryKey, (data) => ({
        ...data,
        ...newBoard,
      }));

      return { previousBoard };
    },
    onSuccess: async (newBoard, variables, context) => {
      queryClient.setQueryData(queryKey, (data) => ({
        ...data,
        ...newBoard,
      }));
    },
    onError: async (_err, _newCard, context) => {
      queryClient.setQueryData(queryKey, context.previousBoard);
    },
    onSettled: () => {
      // queryClient.invalidateQueries('board')
    },
  });
};
