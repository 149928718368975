import { useQueryClient } from "react-query";
import { updatePoint } from "../data/pointReducer";
import { useMutation } from "react-query";
import { useSupabaseClient } from "@supabase/auth-helpers-react";

export const useUpdateListMutation = (queryKey) => {
  const queryClient = useQueryClient();
  const supabase = useSupabaseClient();

  const updateList = async (query) => {
    const { data: list, error } = await supabase
      .from('list')
      .update(query)
      .select()
      .single()
      .eq('id', query.id)

    if (error) {
      console.log('error', error)
      throw new Error(error.message)
    } else {
      return list
    }
  }

  return useMutation(updateList, {
    onMutate: async (newList) => {
      await queryClient.cancelQueries(queryKey);

      const previousBoard = queryClient.getQueryData(queryKey);

      queryClient.setQueryData(queryKey, (data) =>
        updatePoint(data, { id: newList.id }, newList)
      );

      return { previousBoard };
    },
    onSuccess: async (newList, variables, context) => {
      queryClient.setQueryData(queryKey, (data) =>
        updatePoint(data, { id: newList.id }, newList)
      );
    },
    onError: async (_err, _newCard, context) => {
      queryClient.setQueryData(queryKey, context.previousBoard);
    },
    onSettled: () => {
      // queryClient.invalidateQueries('board')
    },
  });
};
