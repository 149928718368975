import { useQueryClient } from "react-query";
import { useMutation } from "react-query";
import { deletePoint } from "../data/pointReducer";
import { useSupabaseClient } from "@supabase/auth-helpers-react";

export const useDeleteListMutation = (queryKey) => {
  const queryClient = useQueryClient();
  const supabase = useSupabaseClient();

  const deleteList = async (query) => {
    const { error } = await supabase
      .from('list')
      .delete()
      .eq('id', query.id);

    if (error) {
      console.log('error', error)
      throw new Error(error.message)
    }
  }

  return useMutation(deleteList, {
    onMutate: async (list) => {
      await queryClient.cancelQueries(queryKey);

      const previousBoard = queryClient.getQueryData(queryKey);

      queryClient.setQueryData(queryKey, (data) => deletePoint(data, list));

      return { previousBoard };
    },
    onError: async (_err, _newCard, context) => {
      queryClient.setQueryData(queryKey, context.previousBoard);
    },
  });
};
