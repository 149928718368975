import { useQueryClient } from "react-query";
import { useMutation } from "react-query";
import { updateSection } from "../data/sectionReducer";
import { useSupabaseClient, useUser } from "@supabase/auth-helpers-react";
import { CARD_QUERY } from "../../util/queries";

export const useFavoriteCardMutation = (queryKey) => {
  const queryClient = useQueryClient();
  const supabase = useSupabaseClient();
  const user = useUser();

  const favoriteCard = async (query) => {
    const { favoriteError } = await supabase
      .from('card_favorite')
      .insert({
        id: query.id,
        user_id: user.id,
      })
      .select()
      .single()

    if (favoriteError) {
      console.log('error', favoriteError)
      throw new Error(favoriteError.message)
    }

    const { data: card, error } = await supabase
      .from('card')
      .select(CARD_QUERY)
      .single()
      .eq('id', query.id)

    if (error) {
      console.log('error', error)
      throw new Error(error.message)
    }

    return card
  }

  return useMutation(favoriteCard, {
    onMutate: async (newCard) => {
      await queryClient.cancelQueries(queryKey);

      const previousBoard = queryClient.getQueryData(queryKey);

      queryClient.setQueryData(queryKey, (data) =>
        updateSection(data, { id: newCard.id }, {
          ...newCard,
          favorite: true,
        })
      );

      queryClient.invalidateQueries(['favorites']);

      return { previousBoard };
    },
    onSuccess: async (newCard, variables, context) => {
      queryClient.setQueryData(queryKey, (data) =>
        updateSection(data, { id: newCard.id }, newCard)
      );
    },
    onError: async (_err, _newCard, context) => {
      queryClient.setQueryData(queryKey, context.previousBoard);
    },
  });
};
