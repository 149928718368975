let POINT_ID = Date.now();

export const addPoint = (data, { point: inPoint, index, name }) => {
  const point = inPoint
    ? { ...inPoint }
    : {
        id: POINT_ID++,
        name,
        lists: [],
      };

  let lists = [...data.lists];
  if (typeof index === "number") {
    lists.splice(index, 0, point);
  } else {
    lists.push(point);
  }

  return {
    ...data,
    lists,
  };
};

export const deletePoint = (data, { id }) => {
  return {
    ...data,
    lists: data.lists
      .filter((list) => list.id !== id)
      .map((list, i) => {
        return {
          ...list,
          index: i,
        };
      }),
  };
};

export const updatePoint = (data, oldList, newList) => {
  return {
    ...data,
    lists: data.lists.map((list) => {
      if (list.id === oldList.id) {
        return {
          ...list,
          ...newList,
        };
      }
      return list;
    }),
  };
};

export const movePoint = (data, { point, index }) => {
  return addPoint(deletePoint(data, { id: point.id }), {
    point,
    index,
  });
};
